#contact_home{
    width:100%;
    // background-image: linear-gradient(to bottom, rgba(201, 19, 42, 0.5),rgba(33, 36, 61, 0.5)), url("../img/uploades/big-ben.jpg");
    background-size: cover;
    background-position: center;
  background-attachment: fixed;
    height: 100vh;
  }
  .form{
    height:100vh;
  }
  #send_request input, textarea{
    background-color: rgba(0,0,0,0.4)!important;
    border:solid 2px $color-secondary;
    background-color:transparent;
    border-radius: 50px;
    padding: 8px 15px;
    margin-bottom:15px;
    color:black;
    outline: 0;
    transition: ease all 0.3s;
  }
  #send_request input:focus{
  
    border:solid 2px white;
  
  }
  #send_request textarea{
    border-radius: 10px;
  }
  #send_request textarea:focus{
    
    border:solid 2px white;
  }
  #send_request button{
    width: 50%;
    background-color:#C9132A;
    color:white;
    padding: 8px 15px;
    border:none;
    border-radius: 50px;
    transition:0.3s;
  }
  #send_request button:hover{
    background-color:#21243D;
    cursor:pointer;
  }
  #send_request ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }
  #gap_top{
    height:40px;
  }