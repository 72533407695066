@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p{
  word-wrap: break-word;
}
h2{
  word-wrap: break-word;
}
a {
  text-decoration: none;
  margin: 0;
  padding: 0;
}
.color-blue {
  color: #21243d;
}
.color-red {
  color: #c9132a;
}
.color-red:hover {
  color: #c9132a;
}
.color-green{
  color:#08A835;
}
.font-small {
  font-size: 15px;
}
$color-primary: #21243d;
$color-primaryweak: rgba(33, 36, 61, 0.95);
$color-secondary: #c9132a;
$color-yellow-light: #f1ddbf;
$color-green-light: #92ba92;
$color-gray-light: #f2f6f6;

$color-blue: #21253d;
$color-blue-light: #7fb5ff;
$color-white: white;
body {
  background-color: #f6f6f9;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  overflow-x: hidden;
}
.my_modal_class{
  height: 89vh;
  overflow-y: scroll;
  top: 0 !important;
  margin-top: 25vh;
  padding: 20px !important;
}
.bgr-red {
  background-color: $color-secondary;
  // background-color:black;
}
.bgr-blue {
  background-color: $color-primary;
}
/*-----------
background-colours
------------*/
.table_dashboard {
  border-radius: 10px;
  width: 100%;
  border-collapse: collapse;
}
.table_dashboard th,
td {
  padding: 10px;
}
.table_dashboard td {
  border: solid 1px #cccccc;
}
.table_dashboard tr:nth-child(even) {
  background-color: #f2f2f2;
}
.bg-white {
  background-color: white;
}
.icon-edit {
  background-color: $color-secondary;
  padding: 8px;
  font-size: 20px;
  color: white;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
.icon-edit:hover {
  cursor: pointer;
}
.icon-plus {
  background-color: $color-secondary;
  padding: 8px;
  font-size: 20px;
  color: white;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
.icon-plus:hover {
  cursor: pointer;
}
.icon-eye {
  background-color: $color-primary;
  padding: 8px;
  font-size: 20px;
  color: white;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
.icon-eye:hover {
  cursor: pointer;
}
.icon-remove {
  background-color: $color-primary;
  padding: 8px;
  font-size: 20px;
  color: white;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
.icon-remove:hover {
  cursor: pointer;
}
.bg-aside {
  // background-color: $color-primary;
  background: linear-gradient(110deg, rgba(33,36,61,1) 19%, rgba(201,19,42,1) 100%);

}
.btn-form {
  background-color: $color-secondary;
}
.bg-blue-primary {
  background-color: $color-primary;
}
.bg-secondary {
  background-color: $color-secondary;
}
.bg-gray {
  background-color: #cccccc;
}
.bg-gray-light {
  background-color: #f2f6f6;
}
.bg-black {
  background-color: black;
}
.bg-black-light {
  background-color: #383838;
}
.bg-blue {
  background-color: $color-blue;
}

.bg-blue-light {
  background-color: $color-blue-light;
}
.bg-coral{
  background-color: coral;
}
.bg-cyan{
  background-color: cyan;
}

.bg-silver{
  background-color: silver;
}
.bg-brown{
  background-color: brown;
}
.bg-lime{
  background-color: lime;
}
.bg-salmon{
  background-color: salmon;
}
.bg-light-pink{
  background-color: lightpink;
}
.bg-magenta{
  background-color: magenta;
}
.bg-maroon{
  background-color: maroon;
}
.bg-orange{
  background-color: orange;
}
.bg-olive{
  background-color: olive;
}
.bg-peach{
  background-color: peachpuff;
}
.bg-pink{
  background-color: palevioletred;
}
.bg-purple{
  background-color: purple;
}
.bg-tan{
  background-color: tan;
}
.bg-violet{
  background-color: violet;
}
.bg-yellow{
  background-color: yellow;
}
/*-----------
colours
------------*/
.colour-white {
  color: white;
}
.colour-primary {
  color: $color-primary;
}
.colour-secondary {
  color: $color-secondary;
}
.colour-red {
  color: $color-secondary;
}
.colour-gray {
  color: #cccccc;
}
.colour-gray-light {
  color: #f2f6f6;
}
.colour-black {
  color: black;
}
.colour-black-light {
  color: #383838;
}
.colour-blue {
  color: $color-blue;
}

.colour-blue-light {
  color: $color-blue-light;
}
.text-red {
  color: #c9132a;
}
.nav-icon {
  color: $color-secondary;
}
.nav-icon:hover {
  $color: $color-blue-light;
}
/*-----------
Helpers
------------*/
.overflowx {
  overflow: auto;
}

.container {
  max-width: 1200px;
  margin: 20px auto;
}
.full-container {
  width: 100%;
}

.input__form {
  background-color: white;
  width: 100%;
  padding: 10px;
  border: solid 2px #dadedf;
  border-radius: 50px;
  margin-bottom: 20px;
  font-size: 1rem;
  outline: 0;
}
.btn__forms {
  background-color: $color-secondary;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0px auto 20px auto;
  width: 100%;
  border-radius: 50px;
  font-size: 1.125rem;
  transition: 0.3s ease all;
}
.btn__forms:hover {
  cursor: pointer;
}
.btn__primary {
  background-color: $color-secondary;
  border: none;
  color: white;
  padding: 10px 2px;
  border-radius: 5px;
  margin: 0px auto 20px auto;
  width: 100%;
  border-radius: 50px;
  font-size: 1.125rem;
  transition: 0.3s ease all;
}
.btn__primary:hover {
  cursor: poiner;
}
.btn__create {
  background-color: $color-secondary;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 0px auto 20px auto;
  border-radius: 50px;
  font-size: 1.125rem;
  transition: 0.3s ease all;
  font-size: 16px;
}
.btn__create:hover {
  cursor: poiner;
}
//  /* ----- REGISTER AND LOGIN ----- */
//  input, select{
//     border-radius:5px;
//     border:solid 2px var(--colorGrayLight);
//     padding: 8px 5px;
//     margin-bottom: 15px;
//   }
//   input, select:focus{
//     background-color: white;
//     border-radius: 50px;
//     outline: 0;
//   }
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}
.image__fluid {
  width: 100%;
}
.msgError {
  color: $color-secondary;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  margin-top: -10px;
  margin-bottom: 10px;
}
.msgErrorWhite {
  color: white;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  margin-top: -10px;
  margin-bottom: 10px;
}
.icon {
  font-size: 30px;
}
// /* to remove the autofill color inputs */
@media (max-width: 1000px) {
  .container {
    width: 90%;
    margin: auto;
  }
  .full-container {
    width: 90%;
    margin: auto;
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2000!important;

  &__content {
    background-color: white;
    padding: 30px;
    border-radius: 10;
   animation-name: godowm!important;
   animation-duration: 0.7s;
   transform: translateY(-100px);
  }
  @keyframes godowm {
    from {transform: translateY(-500px); opacity:0.5}
    to {transform: translateY(-100px); opacity:1}
  }
}

.close-modal {
  color: $color-secondary;
  font-size: 30px;
  display: inline-block;
}
.close-modal:hover {
  cursor: pointer;
}
.swal2-container {
  z-index:2001;
}
.text-center {
  text-align: center;
}
.input {
  background-color: transparent !important;
  margin-bottom: 5px;
  border: none;
  border-radius: 5px;
  width: 100% !important;
  outline: 0;
}
.border-input {
  border: solid 2px #dadedf !important;
  border-radius: 5px;
  padding: 7px 7px;
}
/*---- PRVIEW IMAGE FIELD FILE------*/
#containerImg {
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  max-height: 145px;
  max-width: 145px;
  text-align: center;
  margin: auto;
  object-fit: cover!important;
}
#containerImg img {
  border-radius: 100%;
  width: 100%;
  height: 140px;
  object-fit: cover;
  object-position: center;
}

#containerImg #foto {
  display: none;
}
#containerImg .camaraIcon {
  color: $color-secondary;
  cursor: pointer;
  position: absolute;
  top: 60%;
  right: -2px;
  font-size: 30px;
}
/*---- PRVIEW IMAGE FIELD FILE------*/
#containerImgCover {
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  height: auto;
  max-width: 1200px;
  text-align: center;
  margin: auto;
  object-fit: cover;
}
#containerImgCover img {
  object-fit: cover;
  object-position: center;
}
#label_images {
  border: dashed 2px $color-secondary;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#container_images {
  display: flex;
  justify-content: flex-start;
  gap:15px;
  height: 150px;
}
.container_image{
  background-color: white;
  padding: 5px;
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 position: relative;
 width: 125px;
 height: 125px;

}
.gallery_remove{
  color:$color-secondary;
  position: absolute;
  top:0px;
  font-size: 20px;
  right:-5px;
  z-index:10;
}
.gallery_remove:hover{
  cursor:pointer;
}
.camaraIcon:hover {
  cursor: pointer;
}
//   input number
#input_number {
  box-shadow: 0 0 3px rgba (0, 0, 0, 0.3);
  display: flex;
}
input[type="number"] {
  appearance: textfield;
  text-align: center;
  font-size: 16px;
  border: none;
  width: 100px;
  padding: 0;
  margin: 0;
  outline: 0;
  color: black;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
.button {
  background-color: white;
  color: red;
  border: none;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}
#btn_decrement {
  padding: 7px 5px 7px 15px;
  border-radius: 45px 0 0 45px;
}
#btn_increment {
  padding: 10px 15px 10px 5px;
  border-radius: 0 45px 45px 0;
}
.page-link {
  color: $color-secondary;
}

.stickynav{
    position: sticky;
    top:0;
}
.stickyfooter{
    position: sticky;
    bottom:0;
    right: 0;
}

.slider1{
    background-color:salmon; 
    width:400px;
     height:400px; 
     display:flex;
     justify-content: center;
     align-items: center;

}
.fa-question-circle:hover{
  cursor: pointer;
}

.order_detail{
  width: 550px;

  td{
    padding: 5px;
  }
   p{
    margin:0px 0px 5px 0px;
    font-size:12px
   }
}
#order-details{
  width: 550px;
  border-collapse: collapse;
 
  th{
    background-color: $color-primary;
    color:white;
    padding: 5px;
    text-align: center;
    font-size: 12px;
  }
  tr:nth-child(odd) {background-color: #f2f2f2;}
  td{
    font-size: 12px;
  
  }
}
#order_values{
  width: 550px;
  tr{
    padding: 5px;
    text-align: left;
    font-size: 12px;
  }
  
  h3{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  p{
    margin:0px 0px 5px 0px;
    font-size:12px
   }
}
.external:hover{
  cursor:pointer;
}
@media screen and (max-width: 520px){
 
  #contact_home{
    height: auto!important;
    padding: 50px 0px 40px 0px;
    overflow: hidden;
  }
 .form{
    height:auto;
  }
}

.terms{
  max-width: 1000px;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
}

.menuaside{
  li{
   list-style: none;
  }
  li> ul{
    padding-left:20px;
    display: none;
  }
  li:hover ul{
    cursor: pointer;
    display:block;
  }
}
.padding-list{
  padding-left:15px;
}
.hover{
  cursor: pointer;
}
.pagination{
  margin: 30px 0;
  max-width: 500px;
  width: 100%;
  justify-content:flex-start;
  align-items: center;
  height: 35px;

  gap:10px;
  transition: .1s ease all;
  .active{
    background-color: $color-secondary!important;
    color:white!important;
    padding: 5px 8px;
    border-radius: 2px;
  }
  .page-num{
    background-color: white;
    color:$color-primary;
    padding: 5px 8px;
    border-radius: 2px;
  }
  .page-num:hover{
    background-color: $color-secondary;
    color:white;
    padding: 5px 8px;
    border-radius: 2px;
  }
}
.nav-link:hover{
  color:$color-secondary!important;
}
.seller{
  padding: 10px;
  max-width:1000px;
  margin: 30px auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 1; 
  &__box{
    background-color: transparent;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1; 
  }
  &__status{
    background-color: $color-primary;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  &--complete{
    background-color: $color_secondary;
  }
  &__bar{
    z-index: 100; 
    height: 10px;
    margin-top: 10px;
    background-color: $color-primary;
    width: 450px;
  }
  &--bcomplete{
    background-color: $color_secondary;
  }
}