.recoverpassword{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    &__logo{
        width: 90%;
        display: blck;
        margin-bottom: 20px;
    }
    &__form{
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 5px;
        padding: 40px;
        max-width: 500px;
        width: 100%;
     
    }
    &__password{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        width:100%;
        padding: 10px;
        border:solid 2px #dadedf;
        border-radius:50px;
        margin-bottom:20px;
        font-size: 1.125rem;
        outline: 0;
        
    }
    &__passwordinput{
        background-color: white;
        border:none;
        outline: 0;
        width: 95%;
    }
    &__eye{
        color:$color-secondary;
        width: 5%;
    }
    
    &__recoverpassword{
        display: flex;
        justify-content: center;
        align-items: center;
    }
&__loginhere{
    padding-left: 10px;
}
    
}