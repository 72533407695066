.gallery {
  max-width: 600px;

  width: 100%;
  &__image {
    width:100%;
    height: 500px;
    max-height: 500px;
    overflow: hidden;
    display: block;
    margin: auto;
    transition: 0.5s ease all;
    -webkit-transition: 0.5s ease all;
    -moz-transition: 0.5s ease all;
    -ms-transition: 0.5s ease all;
    -o-transition: 0.5s ease all;
  }
  &__container {
    display: flex;
    justify-content:center;
    margin: 20px auto;
  }
  &__thumbnail {
    margin-right: 10px;
    padding: 5px;
    width: 80px;
    height: 80px;
  }
  &__thumbnail:hover {
    cursor: pointer;
  }
  &__selected {
    border: solid 2px #cccccc;
  }
}
.newgallery{
  max-width: 1200px;
  margin:50px auto;
}
@media screen and (max-width:520px){
  .gallery{
    &__image {
      height: auto;
  }
}
}