.categorySlider{
    display: grid;
    gap:20px;
    grid-template-columns: repeat(4, 1fr);
    margin: auto;
}

@media (max-width:600px){
    .categorySlider{
        gap:10px;
        grid-template-columns:1fr;
    }
}




.mainImg{
    position: relative;
    width: 80%;
    height: 25em;
    margin: 7.43em;
    border-radius: 31.25em;
    border: 15px solid #22243D;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: white;
}
.mainImg img{
    width: 70%;
    border-radius: 20px;
}
.aboutUs {
    font-variant: small-caps;
    color: #22243D;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 5.5em;
}
.descriptionContainer{
    margin-right: 3.43em;
    margin-left: 3.43em;
}
.descriptionContainer .ConPart{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.details{
    width: 50%;
    margin: 2rem;
    color: black;
    font-family: 'Montserrat';
    line-height: 20px;
    font-weight: 400;
}
.detailPic{
    width: 44%;
    height: 335px;
    margin: 2rem;
}
.meetOurTeam {
    width: 100%;
    height: 25.6em;
    top: 112em;
    background-color: #e0e3ff;
}
.meetOurTeam img {
    margin-left: 3.43em;
    margin-right: 3.43em;
    width: 90%;
    height: 25.6em;
    mix-blend-mode: multiply;
}
.meetOurTeam h2 {
    position: relative;
    height: 2.6em;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 3.125em;
    line-height: 83.9%;
    text-align: center;
    letter-spacing: 0.71em;
    font-variant: small-caps;
    color: #fffcfc;
    bottom: 1.7em;
}
.team_container {
    padding-top: 4em;
    max-width: 98vw;
}
.team {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.member {
    width: 30%;
    height: 25em; 
}
.member img {
    width: 65%;
    mix-blend-mode: multiply;
    border: 13px solid rgb(34, 36, 61);
    border-radius: 50%;
    align-items: center;
    display: flex;
    margin: auto;
}
.name {
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    text-align: center;
    font-variant: small-caps;
    color: #000000;
    margin: 0;
}
.designation {
    text-align: center;
    margin: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    text-align: center;
    font-variant: small-caps;
    color: #C9132A;
}
.video{
    position: relative;
    top: 10em;
    display: flex;
    justify-content: center;
    margin: 0px;
}
video{ 
    box-sizing: border-box;
    width: 90%;
    height: 100%;
    margin: 2em;
    border: .3em solid #22243D;
    border-radius: 7em;
    z-index: -1;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    display: block;
    margin: auto;
    margin-bottom: 3rem;
}
.video1{
    box-sizing: border-box;
    max-width: 75em;
    margin: 2em;
    border: .3em solid #22243D;
    border-radius: 3em;
    z-index: -1;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    display: block;
    margin: auto;
}
.noShowLogo{
    display: none;
    background-color: #22243D;
}
.socialMobile{
    padding: 3em;
}
.socialMobile:hover{
    padding: 1em;
    background-color: #080a28;
    border-radius: 10em;
}

@media screen and (max-width: 800px) {
    .ConPart{
        flex-direction: column;
    }
    .ConPart1{
        flex-direction: column-reverse;
    }
    .details{
        width: 100%;
    }
    .detailPic{
        width: 100%;
    }
    .meetOurTeam {
        width: 100%;
    }
    .meetOurTeam img {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
}
@media screen and (max-width: 600px) {
    .mainImg{
        height: 15em;
        margin: 1em;
        border: 10px solid #22243D;
    }
    .mainImg img{
        border-radius: 31.25em;
    }
    .aboutUs {
        font-size: 4em;
    }
    .descriptionContainer{
        margin-left: 0;
    }
    .member {
        width: 50%;
        height: 17em; 
    }
    .member img {
       border: 10px solid rgb(34, 36, 61);
    }
    .name {
        font-size: 20px;
    }
    .designation {
        font-size: 18px;
    }
    .meetOurTeam {
        height: 18em;
    }
    .meetOurTeam img {
        height: 18em;
    }
    .meetOurTeam h2 {
        font-size: 2em;
        letter-spacing: 0.4em;
        bottom: 2em;
    }
    video{
        border-radius: 4em;
    }
    .video1{
        width: 85vw;

    }
}
@media screen and (max-width: 1200px) {
    .video1{
        max-width: 35rem;
    }
}
@media screen and (max-width: 360px) {
    .mainImg{
        height: 12em;
        border: 8px solid #22243D;
        border-radius: 31em;
    }
    .aboutUs {
        font-size: 3em;
    }
    .member {
        width: 70%;
        height: 15em; 
    }
    .member img {
       border: 8px solid rgb(34, 36, 61);
    }
    .meetOurTeam {
        height: 15em;
    }
    .meetOurTeam img {
        height: 15em;
    }
    .meetOurTeam h2 {
        font-size: 1.5em;
        letter-spacing: 0.3em;
    }
}



