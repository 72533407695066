.load {
    background-color: rgba(246,246,249,0.5);
  width: 100%;
  height: 100vh;
  z-index: 100;
position: fixed;
  top: 0;
  display: flex!important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-transition: 1s ease all!important;
  transition: 1s ease all;
  &__spinner {
    border: solid 10px #21243d;
    border-top: solid 10px #c9132a;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    -webkit-animation: rotate 1.5s linear infinite;
    animation: rotate 1.5s linear infinite;
  }
  &__image {
   width: 60px;
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
