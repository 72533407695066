.error404{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    &__content{
        background-color: white;
        max-width:800px;
        width: 100%;
        padding: 40px;
        text-align: center;
    }
    &__title{
        margin-bottom: 20px;
        color:$color-primary;
    }
    &__image{
        max-width: 500px;
        width: 100%;
    }
}