.store {
  width: 100%;
  max-width: 1200px;
  margin: 100px auto 50px auto;
  
  &__banner {
    border: solid 5px white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    height: 350px;
    position: relative;
    margin-bottom: 70px;
    border-radius: 5px;
    width: 100%;
  }
  &__cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__container {
    object-fit: cover;
    width: 180px !important;
    height: 180px !important;
    border: none;
    border-radius: 100%;
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translate(-50%);
   
  }
  &__logo {
    border: solid 10px white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    width: 180px;
    object-fit: cover;
    height: 100%;
    border-radius: 100%;
  }
  &__products{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap:20px;
    margin: 20px auto;
  }
  &__product{
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  &__image{
    width: 150px;
    height: auto;
   
  }
}
// media queries
@media screen and (max-width: 900px){
 
  .store{
    margin: 20px auto;
    padding: 20px;
    &__products{
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media screen and (max-width: 1200px){
  .store{
    padding: 20px;
  }
}
@media screen and (max-width: 600px){
 
  .store{
    margin: 20px auto;
    padding: 20px;
    &__products{
      grid-template-columns: 1fr;
    }
  }
}