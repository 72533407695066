.updatepassword{
    height: 100vh;
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    &__content{
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
        margin: 0 auto;
        width: 100%;
        max-width: 1000px;
        text-align: center;
        padding: 50px;
    }
    &__title{
        font-size: 35px;
        font-weight:500;
    }
    &__image{
        max-width: 100%;
    }
}