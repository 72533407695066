
.slider{
    
    width: 100%;
    height: 600px;
    margin-top:61px;
    display: flex!important;
    align-items: center;
    justify-content: center;
    position: relative;
    &__image{
        width: 100%;
        object-fit: cover;
    }
    &__content{
        position: absolute;
        left:30%;

    }
}
@media screen and (max-width: 900px){
    .slider{
        display:none!important;
        
    }
    .slider_mobile{
        margin:20px auto;
    }
    }
    @media screen and (min-width: 900px){
        .slider_mobile{
            display: none;
        }
        
        }