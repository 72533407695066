.table-cart {
    border:none;
     margin: auto;
     width: 100%;
   }
   
   .table-cart-tr {
     border-radius:10px;
     box-shadow:0px 0px 5px rgba(0,0,0,0.5);
     margin-bottom:20px;
     display: block;
   }
   .table-cart td {
     padding: 10px 10px;
   }
   
   .table-cart th {
     color: #ccc;
     padding: 5px 10px;
     text-align: center;
   }
   
   .table-cart .delete-icon {
     font-size: 25px;
   }
   
   .table-cart .delete-icon:hover {
     cursor: pointer;
   }
