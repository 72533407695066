$base-color: #f0e3d1;
$font-color: #f77d0a;
.advertising{
    .heading{
        font-size: 25px;
        font-weight: bold;
        width: 30%;
        margin-top: 1rem;
        margin-left: 1rem;
        color: $font-color;
    }
    .Container{
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        .Con{
            .Heading{
                font-weight: 20px;
                margin: 0.5rem 0;
            }
        }
        .card{
            .adv-img{
                height: 160px;
                border-radius: 5px;
                width: 300px;
                position: relative;
            }
            .CloseCon{
                position: relative;
                .CloseCicle{
                    position: absolute !important;
                    top: 3%;
                    right: 3%;
                    cursor: pointer;
                }
            }
            .shopBtn{
                border: none;
                background-color: black;
                color: white;
                border-radius: 10px;
                bottom: 10px;
                right: 10px;
                font-size: 13px;
            }
            .detail1, .detail2{
                font-weight: bold;
                top: 3px;
                left: 10px;
                color: white;
            }
            .detail2{
                top: 20px;
            }
        }
    }
    .Container1{
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        .logo-img{
            width: 150px;
            height: 150px;
            border-radius: 50%;
            border: none;
            background-color: white;
            font-size: 60px;
            color: gray;
            text-align: center;
            margin: 1rem 0;
            
        }
    }
    .Container2{
        width: 95%;
        margin: 1rem;
        background-color: white;
        border-radius: 5px;
        .div{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 60%;
            padding: 0.5rem 1rem;
            .field{
                font-size: 18px;
                width: 48%;
                .input{
                    width: 100%;
                    padding: 0.5rem 0;
                    border: none;
                    border-bottom: 2px dashed gray;
                    background-color: white;
                }
            }
        }
    }
    @media(max-width: 400px){
        .Container{
            .card{
                .adv-img{
                    width: 200px;
                }
            }
        }
        .Container1{
            .logo-img{
                width: 100px;
                height: 100px;
            }
        }
        .Container2{
            .div{
                flex-direction: column;
                width: 90%;
                .field{
                    width: 90%;
                }
            }
        }
    }
}