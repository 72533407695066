.product {
  max-width: 1200px;
  height: auto;
  width: 100%;
  margin: 50px auto;
  display: grid;
  margin-top: 100px;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "image details"
    "reviews reviews";

  &__container {
    grid-area: image;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__details {
    grid-area: details;
  }
  &__reviews {
    grid-area: reviews;
  }
  &__name {
    font-size: 50px;
    font-weight: 300;
  }
  &__cost {
    color: $color-secondary;
    font-size: 30px;
  }
  &__promotion {
    color: $color-primary;
    font-size: 30px;
  }
  &__container {
    background-color: white;
    padding: 20px;
  }
  &__image {
    width:100%;
    height:auto;
    margin: auto;
    display: block;
  }
  &__rating {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 20px auto;
  }
  &__addtocart {
    display: flex;
  }
  &__displayreviews {
    background-color: #454546;
    padding: 5px;
    color: white;
  }
  &__displayreviews:hover {
    cursor: pointer;
  }
  &__containerreviews {
    background-color: white;
    padding: 20px;
  }
  &__colors{
    display:flex;
    margin: 15px auto;
  }
  &__color{
    width: 25px;
    height: 25px;
    margin-right: 10px;
    box-shadow: 0px 0px 5px rgba (0,0,0,0,5);
    border:none;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
}
&__color:hover{
  cursor:pointer;
}
&__size{
  margin: 15px auto;
}
&__titlecolors{
  font-size: 18px;
}
&__select{
  width: 50%;
  background-color: white;

  border: solid 2px #dadedf;
  border-radius: 5px;
  padding: 10px 7px;
}
}
// media queries
// @media screen and (max-width: 900px){
 
//   .product{
//     margin: 20px auto;
//     padding: 20px;
   
//       grid-template-columns: repeat(2, 1fr);
//   }
// }
@media screen and (max-width: 900px){
 
  .product{
    margin: 20px auto;
    padding: 20px;
  
      grid-template-columns: 1fr;
      grid-template-areas:
      "image"
      "details"
      "reviews";
      &__image {
        height: auto!important;
      }
  }
}

.tax-icon {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.tax-tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tax-icon:hover .tax-tooltip {
  visibility: visible;
  opacity: 1;
}
