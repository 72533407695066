.productlist{
    margin: 80px auto 50px auto;
    max-width: 1200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    
}
.item{
    display: flex;
    background-color: white;
    margin: 15px auto;
    padding: 20px;
    width: 100%;
    &__containerimage{
        width:100px;
    }
    &__image{
        width:100px;
        height: 100px
      
    }
    &__content{
        width: 1000px;
        margin-left: 10px;
    }
    &__name{
        font-size:20px;
        color:$color-secondary;
    }
    &__description, &__borough, &__cost{
        margin: 0;
    }

    
}
