#categories{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(3, auto);
    gap:20px;
  
    grid-template-areas:'title title title title title title title title title title title title' 
                        'top_left_product top_left_product top_left_product center_product center_product center_product center_product center_product center_product top_right_product top_right_product top_right_product'
                         'bottom_left_product bottom_left_product bottom_left_product bottom_left_product bottom_left_product bottom_left_product bottom_right_product bottom_right_product bottom_right_product bottom_right_product bottom_right_product bottom_right_product';
  }
  #categories  .title{
        font-size: 30px;
        font-weight: 600;
        grid-area: title;
        text-align: center;
        padding:20px;
        
    }
    #categories .top_left_product{
        grid-area: top_left_product;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgba(0,0,0,0.25);
    }
    #categories .center_product{
        grid-area:center_product;
        // background-image: url("../img/uploades/fashion.jpg");
        background-size:cover;
        background-repeat: no-repeat;
        background-position: top center;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgba(0,0,0,0.25);
    }
    #categories .top_right_product{
        grid-area:top_right_product;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgba(0,0,0,0.25);
    }
    #categories .bottom_left_product{
        grid-area: bottom_left_product;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgba(0,0,0,0.25);
    }
    #categories .bottom_right_product{
        grid-area:bottom_right_product;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgba(0,0,0,0.25);
    }
  
    .btn-red {
      background-color: #C9132A;
      color: white;
      text-align: center;
      border: none;
      border-radius: 100px;
      padding: 5px 10px;
      width: 96px;
      display: inline-block;
      text-decoration: none;
      font-weight: 400;
      font-size: 10px;
      line-height: 1.3713rem;
      letter-spacing: 1px;
    }
    
    .btn-line-black {
      color: black;
      text-align: center;
      border: none;
      border: solid 2px black;
      border-radius: 100px;
      padding: 5px 10px;
      display: block;
      width: 100px;
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.3713rem;
      letter-spacing: 1px;
    }
    
    .label-black {
      background-color: black;
      color: white;
      text-align: center;
      border: none;
      border-radius: 100px;
      padding: 5px 10px;
      display: inline-block;
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3713rem;
      letter-spacing: 1px;
    }
    .bg-gray {
      background-color: #C1C1C1;
    }
  /* END CATEGORIES  */
  @media screen and (max-width: 800px){
    #categories{
      display: grid!important;
      grid-template-columns: repeat(12, 1fr);
      grid-template-rows: repeat(6, auto);
      gap:20px;
    
      grid-template-areas:'title title title title title title title title title title title title' 
                          'center_product center_product center_product center_product center_product center_product center_product center_product center_product center_product center_product center_product'
                          'top_left_product top_left_product top_left_product top_left_product top_left_product top_left_product top_right_product top_right_product top_right_product top_right_product top_right_product top_right_product'
                           'bottom_left_product bottom_left_product bottom_left_product bottom_left_product bottom_left_product bottom_left_product bottom_left_product bottom_left_product bottom_left_product bottom_left_product bottom_left_product bottom_left_product'
                           'bottom_right_product  bottom_right_product bottom_right_product bottom_right_product bottom_right_product bottom_right_product bottom_right_product bottom_right_product bottom_right_product bottom_right_product bottom_right_product bottom_right_product';
    }
  }