.order{
    background-color: white;
    padding: 10px 0px;
    &__numbertracker{
        display:grid;
        grid-template-columns: repeat(3, 1fr);
        justify-content: space-between;
        align-items: center;
    }
    &__number{
        background-color: white;
        border-radius: 10px;
        color:#222222;
        padding: 7px 15px;
        //border-radius: 50px;
        box-shadow: 0px 0px 5px rgba (0,0,0,0.5);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
}
&__download{
   
    display: flex;
    justify-content: center;
}
&__downloadbutton{
    background-color:$color-secondary;
    border-radius: 10px;
    display: flex;
   
    align-items: center;
    justify-content: center;
    color:white;
    padding: 7px 15px;
    border:none;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
&__downloadbutton:hover{
    color:white;
}
&__tracker{

    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
}
&__trackerbutton{
  
    background-color: $color-secondary;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    padding: 7px 15px;
    border:none;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
&__trackerorders{

    display: flex;
    justify-content: center;
}

&__tracker_title{
    font-size: 16px;
    margin: 0;
}
&__dispute{
   padding-right:10px ;
    display: flex;
    justify-content: flex-end;
}
&__disputebutton{
    background-color: $color-secondary;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    padding: 7px 15px;
    border:none;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
&__disputebutton1{
    background-color: $color-primary;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    padding: 7px 15px;
    border:none;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}
&__dispute_title{
    font-size: 16px;
    margin: 0;
}
&__detail{
   
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
}
&__product{
    display: flex;
}
&__productimage{
    width: 70px;
    height: 70px;
    // object-fit: cover;
    // object-position: center;
}
&__productname{
    font-size: 20px;
    color:#7E7E7E;
    margin: 0;

} 
&__store{
    color:$color-secondary;
    font-weight: 800;
    margin: 0;
}
&__statustitle{
    color:#7E7E7E;
    font-size: 16px;
    margin: 0;
}
&__size{
    color:#7E7E7E;
}
&__quantity{
    color:#7E7E7E;
}
&__status{
    color:$color-secondary;
    font-size: 20px;
    font-weight: 800;
}
&__productimage{
    margin-right:10px;
}
&__titledate{
    color:#7E7E7E;
    font-size: 16px;
    margin: 0;
}
&__delivery{
    color:$color-secondary;
    font-size: 20px;
    font-weight: 800; 
}
&__deliverydate{
    text-align: right;
}
}

.promo{
    height: 350px;
    background-size: cover;
    background-position: top center;
    position: relative;
 
 &__content{
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 80%;
    width: 60%;
    justify-content: center;
    align-items: flex-start;
 }
 &__title{
    font-weight: 800;
    color: $color-primary;
    font-size: 35px;
    margin-top: 40px;
    
 }
 &__button{
margin-top: 20px;
 }
}

@media (max-width:720px){
    .order{
        &__numbertracker{
            grid-template-columns: 1fr;
        }
        &__download{
            margin-bottom: 20px;
        }
        &__tracker{
            justify-content: center;
        }
        &__detail{
            grid-template-columns: 1fr;
        }
        &__product{
            justify-content: center;
        }
        &__deliverydate{
            text-align: center;
        }
        &__dispute{
            display: flex;
            justify-content: center;
        }
    }
   .promo{
    margin: 40px auto;
    background-size:"center center";
    &__content{
        width: 100%;
    }
    &__title{
        font-size: 35px;
    }
   }
}