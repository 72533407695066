.login{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    &__logo{
        width: 90%;
        display: blck;
        margin-bottom: 20px;
    }
    &__form{
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 5px;
        padding: 40px;
        max-width: 500px;
        width: 100%;
     
    }
    &__title{
        text-align: center;
        font-weight: 500;
        font-size: 1.5625rem;
    }
    &__password{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        width:100%;
        padding: 10px;
        border:solid 2px #dadedf;
        border-radius:50px;
        margin-bottom:20px;
        font-size: 1.125rem;
        outline: 0;
        
    }
    &__passwordinput{
        background-color: white;
        border:none;
        outline: 0;
        width: 95%;
    }
    &__eye{
        color:$color-secondary;
        width: 5%;
    }
    
    &__forgotpassword{
        display: flex;
        justify-content: center;
        align-items: center;
    }
&__register{
    color:$color-primary;
    padding-left: 10px;
    position: relative;
}
&__register:hover{
    color:$color-primary;
    cursor:pointer;
}
&__recoverpassword{
    color:$color-primary;
   
}
&__recoverpassword:hover{
    color:$color-primary;
   
}  
&__create{
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    left:15px;
    width:170px;
    position:relative;

}
&__create_menu{
    position: absolute;
    background-color: $color-primary;
    background-color: $color-primary;
    padding: 5px;
    position: absolute;
    top: 30px;
    left:10px;
    display: flex;
    flex-direction: column;
    width: 170px;
    border-radius: 5px;
}
&__menu_option:hover{
    color:$color-secondary!important;

}
&__register{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

}
&__link{
   border-radius: 50px;
}   
}
@media (max-width:500px){
    .login{
        &__forgotpassword{
            flex-direction: column!important;
        }
        &__register{
            display: flex;
            flex-direction: column;
        }
        &__link{
            margin-bottom: 15px;
        }
    }
}