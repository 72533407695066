.home{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



.paymentSuccess1{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}
.content1{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    background-color: #F1F1F1;
    flex-direction: column;
    padding: 3rem;
    border-radius: 2rem;
    height: 60vh;
}
.logoPic1{
    width: 30%;
}
.tick1{
    width: 15%;
}
.mainHeading1{
    font-size: 30px;
    font-weight: bold;
    color: #22243D;
    font-family: 'Montserrat';
}
.detail1{
    font-size: 20px;
    color: #22243D;
    text-align: center;
}
.shopBtn1{
    border: none;
    background-color: #C9132A;
    color: white;
    font-size: 20px;
    border-radius: 0.5rem;
    box-shadow: 2px 2px rgb(122, 122, 122);
    padding: 0.8rem;
    width: 30%;
    margin-top: 2rem;
}
@media screen and (max-width: 1000px){
    .logoPic1{
        width: 50%;
    }
    .tick1{
        width: 20%;
    }
    .shopBtn1{
        width: 50%;
    }
}
@media screen and (max-width: 650px){
    .logoPic1{
        width: 80%;
    }
    .tick1{
        width: 30%;
    }
    .mainHeading1{
        font-size: 25px;
    }
    .shopBtn1{
        width: 80%;
        font-size: 18px;
    }
}
@media screen and (max-width: 500px){
    .content1{
        padding: 2rem;
    }
}