.header {
  background-color: white !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  width: 100%;
  height: 60px;
  background-color: $color-primary;
  position: fixed;
  padding: 5px;
  top: 0;
  z-index: 100;
  &__menu {
    display: flex;
    gap: 20px;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    margin: auto;
  }
  &__logo_img {
    max-width: 200px;
  }
  &__bars {
    color: $color-secondary;
    font-size: 28px;
    transition: 0.3s ease all;
    -webkit-transition: 0.3s ease all;
    -moz-transition: 0.3s ease all;
    -ms-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
  }
  &__bars:hover {
    cursor: pointer;
    color: $color-primary;
  }
  &__borough {
    width: 250px;
  }
  &__select {
    background-color: #f8f9fa;
    border-radius: 10px;
    border: none;
    width: 100%;
    padding: 10px 15px;
  }
  &__search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 280px;
  }
  &__product {
    background-color: #f8f9fa;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: none;
    width: 100%;
    padding: 7px 15px;
    outline: 0;
  }
  &__searchbutton {
    // background: linear-gradient(54deg, rgba(33,36,61,1) 19%, rgba(201,19,42,1) 100%);
    background-color: #21243d;
    border: none;
    color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    text-align: center;
    padding: 5px 10px;
  }
  &__searchbutton:hover {
    cursor: pointer;
  }
  &__login {
    position: relative;
  }
  &__login:hover {
    cursor: pointer;
  }
  &__login_options {
    // background: linear-gradient(54deg, rgba(33,36,61,1) 19%, rgba(201,19,42,1) 100%);
    background-color: $color-primary;
    padding: 5px;
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    width: 140px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    z-index: 10;
  }
  &__register {
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 50px;
    background-color: $color-secondary;

    color: white;
   
    // width: 115px;

    position: relative;
  }
  &__register:hover {
    cursor: pointer;
  }
  &__register_options {
    background-color: $color-primary;
    padding: 5px;
    position: absolute;
    top: 120%;
    display: flex;
    flex-direction: column;
    width: 160px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    z-index: 10;
    transition: 0.3 ease all;
    -webkit-transition: 0.3 ease all;
    -moz-transition: 0.3 ease all;
    -ms-transition: 0.3 ease all;
    -o-transition: 0.3 ease all;
  }
// end register menu

&__about{
  
  padding: 5px;
  color: $color-primary;
  font-size: 16px;
  width:100px;

}
&__about:hover{
  color:$color-primary;
}

&__terms{
 
  padding: 5px 5px;
  color: $color-primary;
    position: relative;
    width:100px;

}
&__terms:hover{
cursor:pointer;
}
&__terms_options {
    background-color: $color-primary;
    padding: 5px;
    position: absolute;
    top: 120%;
    display: flex;
    flex-direction: column;
    width: 180px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    z-index: 10;
    transition: 0.3 ease all;
    -webkit-transition: 0.3 ease all;
    -moz-transition: 0.3 ease all;
    -ms-transition: 0.3 ease all;
    -o-transition: 0.3 ease all;
  }

//term and conditions menu

  &__link {
    color: white;
  }
  &__link:hover {
    color: $color-secondary;
  }
  &__cart {
    position: relative;
  }
  &__cart_icon {
    padding-top: 10px;
    font-size: 25px;
    color: $color-primary;
  }
  &__cart_counter {
    background-color: white;
    border: solid 1px $color-primary;
    color: $color-secondary;
    position: absolute;
    right: -5px;
    top: -3px;
    font-size: 9px;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    width: 13px;
    height: 13px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
  }
  &__stores {
    background-color: $color-secondary;
    // background: linear-gradient(54deg, rgba(33,36,61,1) 19%, rgba(201,19,42,1) 100%);
    color: white;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
  }
  &__stores:hover {
    cursor: pointer;
  }
}
// end navbar

.listsearch {
  margin-top: 50px !important;
  overflow-y: scroll;
  max-width: 670px;
  margin: auto;
  width: 100%;
  height: 100%;
  min-height: 200px;
  max-height: 200px;
  background-color: white;
  position: sticky;
  top: 61px;
  z-index: 5;
  padding: 20px;
  display: flex;
  flex-direction: column;
  &__item {
    color: $color-primary;
    line-height: 32px;
  }
  &__item:hover {
    color: $color-secondary;
  }
}

@media screen and (max-width: 900px) {
  .header {
    display: none;
  }
  .header_mobile {
    background-color: white;
    margin: 0px auto;
    position: sticky;
    top: 0;
    z-index: 100;
  }
}
@media screen and (min-width: 900px) {
  .header_mobile {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .header {
    &__stores {
      padding: 3px 7px;
      font-size: 13px;
    }
  }
}
