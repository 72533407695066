#cookiesbg{
    background-color:rgba(0,0,0,0.5);
    height:100vh;
    width: 100%;
    position: fixed;
    z-index: 1;
  }
  #cookieNotice{
    background-color: white;
    padding:20px;
    border-radius: 5px;
    width: 90%;
    margin:50px auto;
    z-index: 100;
    position: absolute;
    transform: translate(5%, 20%);
  
  }
  #cookieNotice p{
    text-align: justify;
    line-height: 32px;
    font-size: 15px;
  }
  #accept_cookies, #close_cookies{
    background-color:#C9132A;
     padding:10px 15px;
     border-radius: 5px;
     border:none;
     color:white;
     font-size: 15px;
  
  }
  
  #close_cookies{
    background-color:#21243D;
  }