

.subcategories{
    width: 100%;
    margin: 80px auto 50px auto;
    max-width: 1200px;
    &__cont_title{
        background: rgb(33,36,61);
    background: linear-gradient(54deg, rgba(33,36,61,1) 19%, rgba(201,19,42,1) 100%);
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    &__title{
        color:white;
        font-weight: 800;
        font-size: 50px;
    }
    &__container{
        display: grid;
        gap:20px;
        grid-template-columns: repeat(4, 1fr);
        margin: 50px auto;
    }
    &__banner{
        display: grid;
        gap:20px;
        grid-template-columns: 1fr;
        margin: 50px auto;
    }
    &__product{
        background-color: white;
        display: flex;
        flex-direction: column;
        padding: 20px;
      }
      &__image{
        width: 200px;
        height: 200px;
        
      }
}

@media screen and (max-width: 520px){
 
   .subcategories{
    margin: 20px auto 50px auto;
    &__container{
        width: 90%;
        margin: 30px auto;
        grid-template-columns: 1fr;
    }
    &__title{
        font-size: 40px;
    }
   }
  }