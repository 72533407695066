.events{
    width: 100%;
    margin: 100px auto 50px auto;
    max-width: 1200px;
    &__container{
        display: grid;
        gap:20px;
        grid-template-columns: repeat(4, 1fr);
    }
    &__card{
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
}
    &__title{
        margin:10px auto;
    }
    &__date{
        line-height: 10px;
    }
}