.stores{
    width: 100%;
    margin: 100px auto 50px auto;
    max-width: 1200px;
    &__title{
        font-size: 30px;
    }
    &__container{
        display: grid;
        gap:20px;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 50px;
    }
    .store{
        &__card{
            background-color:white;
            border-radius: 10px;
            padding: 20px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -ms-border-radius: 10px;
            -o-border-radius: 10px;
    }
    &__image{
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
        &__title{
           
            margin:10px auto;
            font-size: 20px;
            font-weight: 800;
        }
        &__content{
    
            margin: 0 auto 5px auto;
           
        }
    }
}
@media screen and (max-width: 900px){
    .stores{
        margin: 20px auto;
        padding: 20px;
       &__container{

        grid-template-columns: repeat(2, 1fr)

       }
    }
    
    }
@media screen and (max-width: 600px){
    .stores{
        margin: 20px auto;
        padding: 20px;
       &__container{

        grid-template-columns: 1fr;

       }
    }
    
    }
  
    