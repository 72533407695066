.sellerproducts{
    max-width: 1200px;
    width: 100%;
    margin:30px auto;
    display: grid;
    gap:20px;
    grid-template-columns: repeat(4, 1fr);
    &__container{
        background-color: white;
        padding:15px;
    }

}
.sellernoproducts{
    display: grid;
    grid-template-columns: 1fr;
}

.sellerproducts_pagination{
    max-width: 1200px;
    width: 100%;
    margin:0px auto 30px auto;
}

@media (max-width:1000px){
    .sellerproducts{

        grid-template-columns:repeat(3, 1fr);

    }   
}

@media (max-width:700px){
    .sellerproducts{

        grid-template-columns: 1fr;

    }   
}


.step-box {
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin: 20px 0;
  }
  
  .step-box h2 {
    margin-top: 0;
  }
  
  .step-box ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .step-box li {
    padding: 10px 0;
    font-size: 18px;
    line-height: 1.5;
  }
  
  .step-box li:before {
    content: "\2022"; /* bullet symbol */
    color: #007bff;
    font-size: 24px;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }


  .step-box1 {
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin: 20px 0;
  }
  
  .step-box1 h2 {
    margin-top: 0;
  }
  
  .step-box1 ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .step-box1 li {
    font-size: 14px;
    line-height: 1.5;
  }
  
  .step-box1 li:before {
    content: "\2022"; /* bullet symbol */
    color: #007bff;
    font-size: 24px;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  .search-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .search-form {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .search-prod {
    background-color: #f2f2f2;
    border: none;
    padding: 12px 20px;
    border-radius: 50px;
    font-size: 16px;
    color: #666;
    width: 500px;
    transition: all 0.3s ease-in-out;
  }
  
  .search-prod:focus {
    outline: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: 600px;
  }

  .event-vid{
    width: 575px;
  }
  
  
  
  