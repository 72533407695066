.footer{
    background-color: $color-primary;
    &__icon{
        color:$color-secondary;
        font-size: 20px;
    }
    &__icon_right{
        color:$color-secondary;
        font-size: 25px;
    }
    &__icon_bottom{
        color:$color-secondary;
    }
    &__container{
        max-width: 1100px;
        margin: auto;
        width: 100%;
    }
}

.color3{
    color:#F8F9FA;
}
@media (max-width:520px){
    .footer{
        padding: 15px;
    }
}