.aside {
  background-color: white;
  // box-shadow: 0px 0px 5px rgba(0,0,0,0.5);
  border-top-right-radius: 10px !important;

  border-bottom-right-radius: 10px;
  width: 250px;
  height: 100vh;
  position: fixed;
  margin-left: -250px;
  top: 1px;
  left: 0;
  z-index: 200;
  transition: 1.2 ease all !important ;
  -webkit-transition: 1.2 ease all !important ;
  -moz-transition: 1.2 ease all !important ;
  -ms-transition: 1.2 ease all !important ;
  -o-transition: 1.2 ease all !important ;
  &__title {
    // background-color: $color-secondary;
    background: linear-gradient(54deg, rgba(33,36,61,1) 19%, rgba(201,19,42,1) 100%);
    color: white;
    padding: 8px;
    font-size: 20px;
    font-weight: 300;
  }
  &__category {


    width:100%!important;
    margin: auto!important;
    align-items: center;
    display: flex !important;
    flex-direction: column !important;
    list-style: none;
    justify-content: flex-start !important;
  }
  &__name {
    width: 95%;
    margin: auto;
    border-bottom: solid 1px #cccccc;
    margin-bottom: 15px;
    color: $color-primary;
    padding:10px;
    font-size: 18px;
    transition: 0.3s ease all;
    -webkit-transition: 0.3s ease all;
    -moz-transition: 0.3s ease all;
    -ms-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    // display: flex;
    // align-items: center;
  }
  &__name:hover {
    cursor: pointer;
    color: $color-secondary;
  }
  &__icon {
    color: $color-secondary;
    font-size: 18px;
    margin-right: 5px;
  }
  &__list {
    
    flex-direction: column;
    padding: 10px 10px 10px 5px;
    transition: 0.3s ease all;
    -webkit-transition: 0.3s ease all;
    -moz-transition: 0.3s ease all;
    -ms-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
 
  }
  &__link {
    
    list-style: none;
    line-height: 28px;
    font-size: 16px;
    padding-left: 14px;
    margin: 5px auto;
    color: $color-primary!important;
    transition: 0.3s ease all;
    -webkit-transition: 0.3s ease all;
    -moz-transition: 0.3s ease all;
    -ms-transition: 0.3s ease all;
    -o-transition: 0.3s ease all;
    
  }
  &__link_to{
    color:$color-primary;
  }
  &__link_to:hover{
    color:$color-secondary;
  }
  &__link:hover {
    color: $color-secondary;
    cursor:pointer;
  }
  &__dot {
    color: $color-secondary;
    margin-right: 2px;
    font-size: 14px;
  }
  &__dot:hover {
    color: white;
  }
}

.marginleft{
  margin-left: 0;
}